$enable-responsive-font-sizes: true;

@import 'node_modules/bootstrap/scss/bootstrap';

@import './App.scss';

@font-face {
    font-family: 'Eina';
    src: local('Eina'), url(../assets/fonts/Eina-01-Regular.otf) format('opentype');
}

body {
    margin: 0;
    font-family: Eina;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden
}
